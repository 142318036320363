import { render, staticRenderFns } from "./WebsiteCard.vue?vue&type=template&id=a2e19884&scoped=true"
import script from "./WebsiteCard.vue?vue&type=script&lang=js"
export * from "./WebsiteCard.vue?vue&type=script&lang=js"
import style0 from "./WebsiteCard.vue?vue&type=style&index=0&id=a2e19884&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2e19884",
  null
  
)

export default component.exports